import React from "react"
import classNames from "classnames"
import { Container, Row, Col } from "src/ui"
import { CardPage, CardPageProps } from "./CardPage"

interface Props {
  className?: string
  cardData: [CardPageProps, CardPageProps, CardPageProps, CardPageProps]
}

export const ListPages: React.FC<Props> = ({ className, cardData }) => {
  const wrapperClassName = classNames("section section-default pt-0", {
    className: Boolean(className),
  })

  const itemsInFirstCol = Math.ceil(cardData.length / 2)
  const itemsInSecondCol = cardData.length - itemsInFirstCol

  return (
    <section className={wrapperClassName}>
      <Container>
        <Row className="mosaic">
          <Col sm="6">
            {cardData.slice(0, itemsInFirstCol).map((page, idx) => (
              <CardPage key={`first${idx}`} {...page} />
            ))}
          </Col>
          <Col sm="6">
            {cardData
              .reverse()
              .slice(0, itemsInSecondCol)
              .map((page, idx) => (
                <CardPage key={`second${idx}`} {...page} />
              ))}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
