import React from "react"
import { Card, CardImg, CardImgOverlay, CardBody, CardTitle } from "src/ui"
import LocalizedLink from "../localizedLink"

export interface CardPageProps {
  imageUrl: string
  title: string
  linkTo: string
}

export const CardPage: React.FC<CardPageProps> = ({ imageUrl, title, linkTo }) => {
  return (
    <div className="card-container overflow-hidden mb-4">
      <Card className="text-white text-center">
        <CardImg width="100%" src={imageUrl} alt={title} />
        <CardImgOverlay className="d-flex align-items-center card-img-overlay-info">
          <CardBody>
            <CardTitle tag="h4" className="mb-0">
              <span className="border-secondary border-bottom">{title}</span>
            </CardTitle>
            <LocalizedLink to={linkTo} className="stretched-link" />
          </CardBody>
        </CardImgOverlay>
      </Card>
    </div>
  )
}
