import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { PageIntro } from "src/components/PageIntro"
import { Header } from "src/components/Header"
import { ListPages } from "src/components/ListPages"
import { getFluidImageSrc, getFixedImageSrc } from "src/utils/images"
import SEO from "src/components/SEO"
import InViewMonitor from "react-inview-monitor"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Page: React.FC<any> = ({ data: { mdx, allMdx } }) => {
  if (!mdx.frontmatter || !mdx.frontmatter.title || !mdx.frontmatter.intro) {
    return <div>No content has been created for this page</div>
  }

  const {
    intro: { title, lead, content },
  } = mdx.frontmatter

  const subPages = allMdx.edges.map(mdx => {
    const imageToUse =
      mdx && mdx.node && mdx.node.frontmatter && mdx.node.frontmatter.thumbnailImage
        ? mdx.node.frontmatter.thumbnailImage
        : mdx && mdx.node && mdx.node.frontmatter && mdx.node.frontmatter.headerImage
        ? mdx.node.frontmatter.headerImage
        : null
    return {
      imageUrl: imageToUse ? getFixedImageSrc(imageToUse) : undefined,
      title: mdx && mdx.node && mdx.node.frontmatter && mdx.node.frontmatter.title,
      linkTo: mdx && mdx.node && mdx.node.frontmatter && mdx.node.frontmatter.slug,
    }
  })

  return (
    <Fragment>
      <SEO title={mdx.frontmatter.title} />

      {/* HEADER */}
      {mdx.frontmatter && mdx.frontmatter.headerImage ? (
        <Header
          backgroundImageUrl={getFluidImageSrc(mdx.frontmatter.headerImage) || ""}
          videoName={mdx.frontmatter.videoFileName}
          videoNameMobile={mdx.frontmatter.videoFileNameMobile}
        />
      ) : null}

      {/* INTRO */}
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <PageIntro title={title} lead={lead}>
          {content && content.length ? content : <MDXRenderer>{mdx.body}</MDXRenderer>}
        </PageIntro>
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <ListPages cardData={subPages} />
      </InViewMonitor>
    </Fragment>
  )
}

export default Page

export const query = graphql`
  query Page($locale: String!, $title: String!, $sluchMatcher: String) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title
        intro {
          title
          lead
          content
        }
        headerImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
              srcWebp
            }
          }
        }
        videoFileName
        videoFileNameMobile
      }
      body
    }

    allMdx(filter: { frontmatter: { slug: { glob: $sluchMatcher } }, fields: { locale: { eq: $locale } } }) {
      edges {
        node {
          frontmatter {
            title
            slug
            headerImage {
              publicURL
              childImageSharp {
                fixed(width: 600, height: 300, quality: 80) {
                  ...GatsbyImageSharpFixed
                  srcWebp
                }
              }
            }
            thumbnailImage {
              publicURL
              childImageSharp {
                fixed(width: 600, height: 300, quality: 80) {
                  ...GatsbyImageSharpFixed
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
